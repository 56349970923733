@import "../../../styles/main";

.tournament-form__team-fill-form {
    &__tab-group {
        display: flex;
        justify-content: center;

        &__tab-button {
            background-color: $black;
            border-radius: 0;
            font-size: $type-size-md;

            @media not all and (pointer: coarse) {
                &:hover {
                    background-color: $black;
                    color: $white;
                }
            }

            &--deselect {
                background-color: fade-out($black, 0.5);
                color: $grey-100;
            }

            &--warning {
                @media not all and (pointer: coarse) {
                    &:hover {
                        background-color: $black;
                        color: $red-highlight;
                    }
                }
            }

            &:disabled {
                color: $grey-200;

                @media not all and (pointer: coarse) {
                    &:hover {
                        background-color: fade-out($black, 0.5);
                    }
                }
            }
        }
    }
}
