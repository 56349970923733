@import "../../../styles/main";

.team-card {
    align-self: center;
    background-color: fade-out($black, 0.25);
    border-radius: $size-sm;
    display: inline-block;
    font-size: $size-md;
    max-width: 350px;
    margin: $size-xxs;
    padding: $size-xs;
    width: 90%;

    &__add-button {
        background-color: transparent;
        color: $green;
        font-size: $type-size-xl;
        line-height: $type-size-md;
        margin: 0;
        width: 100%;

        @media not all and (pointer: coarse) {
            &:hover {
                background-color: $black;
                color: $green-highlight;
            }
        }
    }

    &__champions {
        margin: $size-xs auto;

        &__champion {
            display: flex;
            justify-content: space-between;

            &__remove-button {
                background-color: transparent;
                color: $red;
                font-size: $type-size-lg;
                line-height: $type-size-sm;

                @media not all and (pointer: coarse) {
                    &:hover {
                        background-color: lighten($black, 10);
                        color: $red-highlight;
                    }
                }

                &:disabled {
                    opacity: 0;
                    cursor: default;
                }
            }
        }
    }
}
