@import "../../../styles/main";

.input-number {
    &__container {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        transform: translateX(-$size-xxs);
    }

    &__number {
        color: $primary;
        font-size: $type-size-sl;
        text-shadow: $size-xxs $size-xxs $size-xxs $black;
        user-select: none;
        width: $type-size-sl;
    }

    &__counter-button {
        background-color: transparent;
        border: none;
        color: $green;
        cursor: pointer;
        font-size: $type-size-ul;
        line-height: $type-size-sl;
        text-shadow: $size-xxs $size-xxs $size-xxs $black;
        user-select: none;

        @media not all and (pointer: coarse) {
            &:hover {
                background-color: transparent;
                color: $green-highlight;
            }
        }

        &:disabled {
            color: transparent;
            cursor: default;
            text-shadow: none;
        }
    }
}
