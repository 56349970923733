html {
    scroll-behavior: smooth;
}

body {
    background-color: $background-color-200;
    background-image: radial-gradient(
        at 50% 30%,
        $background-color-100,
        $background-color-200
    );
    background-repeat: no-repeat;
    color: $white;
    display: flex;
    flex-direction: column;
    font-family: Bangers, sans-serif;
    min-height: 100vh;
    text-align: center;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h1 {
    font-size: $h1;
    color: $primary;
    text-shadow: $size-xxs $size-xxs $size-xxs $black;
}

h2 {
    font-size: $h2;
    color: $secondary;
    text-shadow: $size-xxs $size-xxs $size-xxs $black;
}

h3 {
    font-size: $h3;
    color: $tertiary;
    text-shadow: $size-xxs $size-xxs $size-xxs $black;
}

h4 {
    font-size: $h4;
    color: $white;
    text-shadow: $size-xxs $size-xxs $size-xxs $black;
}

h5 {
    font-size: $h4;
    color: $primary;
    text-shadow: $size-xxs $size-xxs $size-xxs $black;
}

.help-dialog-text {
    font-size: $h4;

    &__p {
        padding-bottom: $size-sm;
    }
}

.beta-tag {
    background-color: $purple-highlight;
    border-radius: 25%;
    font-size: $type-size-sm;
    padding: $size-xxs calc($size-xs - 1px) $size-xxs $size-xxs;
    position: absolute;
    transform: translateY(-50%);
}

@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";
.material-symbols-outlined {
    &__header {
        border-radius: 50%;
        text-shadow: calc($size-xxs / 2) calc($size-xxs / 2) calc($size-xxs / 2)
            fade-out($black, 0.5);
        color: fade-out($blue, 0.5);
        font-size: $size-xl;
        font-variation-settings:
            "FILL" 0,
            "wght" 700,
            "GRAD" 0,
            "opsz" 48;
        margin: $size-sm;
        position: absolute;
        top: 0;
        user-select: none;
        z-index: 1;

        &__help {
            right: 0;
        }

        &__return {
            left: 0;
            rotate: -45deg;
        }

        @media not all and (pointer: coarse) {
            &:hover {
                background-color: #00000044;
                color: $blue-highlight;
                cursor: pointer;
            }
        }
    }

    &__clear {
        font-variation-settings:
            "FILL" 0,
            "wght" 700,
            "GRAD" 0,
            "opsz" 48;
        font-weight: bolder;
        position: relative;
        rotate: -45deg;
        stroke-width: 2px;
    }
}
