// Standard
$h1: 2.5rem;
$h2: 2.125rem;
$h3: 1.75rem;
$h4: 1.375rem;
$body: 1rem;

// Special
$type-size-xxs: 0.25rem;
$type-size-xs: 0.5rem;
$type-size-sm: 1rem;
$type-size-md: 1.5rem;
$type-size-lg: 2rem;
$type-size-xl: 2.5rem;
$type-size-xxl: 3rem;
$type-size-xxxl: 4rem;
$type-size-sl: 5rem;
$type-size-ml: 6rem;
$type-size-ul: 7.5rem;
