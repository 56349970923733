button {
    $self: &;
    background-color: $blue;
    border: none;
    border-radius: $size-xs;
    color: $white;
    font-family: Bangers, sans-serif;
    font-size: $h4;
    margin: 0 $size-sm;
    padding: $size-xxs calc($size-xs / 2 * 3) $size-xxs $size-xs;
    cursor: pointer;
    user-select: none;

    @media not all and (pointer: coarse) {
        &:hover {
            background-color: $blue-highlight;
        }
    }
}

.button {
    &-confirm {
        background-color: $green;

        @media not all and (pointer: coarse) {
            &:hover {
                background-color: $green-highlight;
            }
        }
    }

    &-discard {
        background-color: $red;

        @media not all and (pointer: coarse) {
            &:hover {
                background-color: $red-highlight;
            }
        }
    }
}
