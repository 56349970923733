@import "../../../styles/main.scss";

:root {
    --full-height: 100%;
}

.dialog-background {
    background-color: fade-out($black, 0.25);
    height: var(--full-height);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.dialog {
    display: flex;
    left: 50%;
    max-width: 650px;
    min-width: 350px;
    margin: 10vh $size-xs;
    position: absolute;
    top: 0;
    transform: translateX(-53%);
    z-index: 2;

    &__window {
        background-color: $black;
        border-radius: $size-sm;
        width: 100%;

        &__dialog-body {
            display: flex;
            flex-direction: column;
            gap: $size-xs;
            padding: $size-sm;
        }

        &__close-button {
            background-color: $grey-300;
            border: $black solid $size-xxs / 2;
            border-radius: 50%;
            color: $grey-100;
            float: right;
            font-size: $type-size-sm;
            height: $size-md;
            margin: -$size-xs 0 -$size-xs 0;
            padding: 2px 1.5px 1px 1px;
            width: $size-md;

            &:hover {
                background-color: $blue;
                color: $white;
            }
        }

        &__got-it-button {
            align-self: center;
            max-width: fit-content;
        }
    }
}
