@import "../../../styles/main";

.tournament {
    &__match-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: $size-sm;

        &__horizontal-line {
            background-color: $tertiary;
            box-shadow: $size-xxs $size-xxs $size-xxs $black;
            height: $size-xxs;
            flex-grow: 1;

            &--winner {
                background-color: $primary;
            }
        }

        &__player {
            background: none;
            border: $tertiary solid $size-xxs;
            border-radius: $size-xs;
            box-shadow:
                inset $size-xxs $size-xxs $size-xxs $black,
                $size-xxs $size-xxs $size-xxs $black;
            cursor: pointer;
            font-size: inherit;
            margin: 0;
            min-width: 120px;
            padding: $size-xxs $size-xs;

            @media not all and (pointer: coarse) {
                &:hover {
                    background: none;
                    border-color: $secondary;
                }
            }

            @media screen and (max-width: 375px) {
                min-width: 60px;
                overflow-wrap: break-word;
            }

            &:disabled {
                border-color: $red;
            }

            &--winner {
                border-color: $primary;
            }
        }

        &__winner {
            margin: $size-xs;

            &--decided {
                color: $primary;
            }

            &--undecided {
                color: $secondary;
            }
        }
    }
}
