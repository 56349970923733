input[type="text"] {
    background-color: $grey-100;
    border: none;
    border-radius: $size-sm;
    font-family: Bangers, sans-serif;
    font-size: $h4;
    margin: $size-xxs;
    padding: $size-xxs;
    text-align: center;
}

input[type="text"].input-title {
    background-color: transparent;
    border-radius: $size-xs;
    color: $tertiary;
    font-size: $h3;
    margin: 0;
    padding: 0;
    text-shadow: 4px 4px 4px $black;
    width: 100%;

    @media not all and (pointer: coarse) {
        &:hover {
            background-color: $black;
            cursor: pointer;
        }
    }

    &:focus {
        color: white;
        cursor: text;
        outline: none;
        user-select: all;
    }

    &:disabled {
        background-color: transparent;
        cursor: text;
    }

    &__inactive {
        @extend .input-title;
        color: $red;
    }
}
