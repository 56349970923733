@import "../../../styles/main.scss";

.footer {
    margin-top: auto;
    padding: $size-xs;

    a {
        color: $grey-100;
        text-decoration: underline;
        cursor: pointer;
    }

    p {
        color: $grey-100;
    }

    &__dialog-p {
        text-align: left;
        margin-left: 50%;
        margin-right: 10%;
        transform: translateX(-50%);
    }
}
