@import "../../../styles/main";

.tournament-form {
    &__add-player-dialog {
        &__list-group {
            margin: $size-sm;
            max-width: 300px;

            &__list-button {
                background: none;
                color: $grey-100;
                cursor: pointer;
                font-size: $type-size-lg;
                margin: $size-xxs;

                @media not all and (pointer: coarse) {
                    &:hover {
                        background-color: lighten($black, 2);
                        color: $white;
                    }
                }

                &:disabled {
                    opacity: 0.5;

                    @media not all and (pointer: coarse) {
                        &:hover {
                            background: none;
                            color: $grey-100;
                            cursor: default;
                        }
                    }
                }

                &--select {
                    background-color: lighten($black, 2);
                    color: $white;

                    @media not all and (pointer: coarse) {
                        &:hover {
                            background-color: lighten($black, 1);
                            color: darken($white, 5);
                        }
                    }
                }
            }
        }
    }
}
