@import "../../../styles/main";

.tournament {
    display: block;
    text-align: center;
    padding: calc(1.5 * $size-xxl) $size-sm;
    overflow: hidden;

    &__matches {
        margin: $size-sm auto 0 auto;
        display: flex;
        flex-direction: column;
        justify-items: center;
        max-width: 700px;

        &__round {
            background-color: fade-out($black, 0.25);
            border-radius: $size-sm;
            margin: $size-xxs 0;
            padding: $size-xs $size-sm;
        }
    }
}
