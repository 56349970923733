@import "../../../styles/main";

.spin-wheel {
    &__wheel {
        aspect-ratio: 1 / 1;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        min-width: 300px;
        width: 60%;

        &__plate {
            aspect-ratio: 1 / 1;
            border-radius: 50%;
            display: block;
            height: 100%;
            overflow: hidden;
            transition: 3s all;
            width: 100%;
        }

        &__spin-button {
            aspect-ratio: 1 / 1;
            background-color: $secondary;
            border-radius: 50%;
            display: block;
            left: 50%;
            margin: 0;
            padding: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 20%;

            @media not all and (pointer: coarse) {
                &:hover {
                    background-color: $tertiary;
                }
            }

            &:disabled {
                background-color: darken($secondary, 5);
            }
        }

        &__wheel-head {
            background-color: darken($secondary, 25);
            border: darken($secondary, 25) solid;
            box-shadow: 2px 2px 6px 6px $black;
            clip-path: polygon(100% 100%, 0 50%, 100% 0);
            height: 5%;
            left: 95%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 5%;
        }
    }

    &__teams {
        display: inline-flex;
        flex-direction: column;
        vertical-align: middle;

        @media screen and (max-width: 375px) {
            width: 80%;
        }
    }
}
