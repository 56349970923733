// Base colors
$primary: #ffc535;
$secondary: #ff6300;
$tertiary: #ff0000;

// Achromatic colors
$black: #111215;

$grey-100: #6c727c;
$grey-200: #373c47;
$grey-300: #282c34;
$grey-400: #1e2127;

$white: #eeeeee;

// Background and text colors
$background-color-100: #282c34;
$background-color-200: #15161a;
$text-color: $black;

// Chromatic colors
$red: #8b0000;
$red-highlight: $tertiary;
$green: #008000;
$green-highlight: #32cd32;
$blue: #0d6efd;
$blue-highlight: #0dbdfd;
$purple: #590072;
$purple-highlight: #c800ff;

// Semantic colors
$success: $green;
$error: $red;
$warning: #ff9000;
$info: $blue;
